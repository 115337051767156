import { ButtonSize, TypoVariant } from '@constants/atoms';
import { useMemo } from 'react';

export const useGetButtonSize = (buttonSize: ButtonSize) => {
  const size = useMemo(() => {
    switch (buttonSize) {
      case ButtonSize.Large:
        return [TypoVariant.SH3, TypoVariant.SH4, '12px 23px'];
      case ButtonSize.Small:
        return [TypoVariant.SH4, TypoVariant.SH5, '10px 16px'];
    }
  }, [buttonSize]);

  // [PcFontSize, MobileFontSize, padding]
  return size;
};
